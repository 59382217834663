import React from 'react';
import PropTypes from 'prop-types';
import Richtext from '../Richtext/Richtext';
import ImageHandler from '../../utils/ImageHandler';
import PlayStore from '../../../static/images/play_store.svg';
import AppStore from '../../../static/images/app_store.svg';

// ====

const Games = (props) => {
  const { title, copy, image, id, items, layout } = props;
  var itemClass = 'col-lg-10';
  var cardClass = '';
  {
    items.length === 1
      ? ((itemClass = 'col-lg-10'), (cardClass = 'brand-card-one'))
      : ((itemClass = 'col-lg-4'), (cardClass = 'brand-card-multiple'));
  }
  return (
    <article
      id={id}
      className={`w-100 brand-screen brand-screen-${layout}`}
      style={{
        backgroundImage: `url(${ImageHandler(image.filename, '1920x1080')})`,
      }}
    >
      <div className="d-flex w-100 justify-content-center align-items-center">
        <div className="container pt-5 pb-5">
          {title ||
            (copy && (
              <div className="row justify-content-center">
                <div className="col-12 text-center text-white">
                  {title && <h1 className="brand-font mb-3">{title}</h1>}
                  {copy && <Richtext content={copy} />}
                </div>
              </div>
            ))}
          {items && (
            <div className="row justify-content-center pt-5">
              {items.map((node) => {
                return (
                  <div className={`col-12 ${itemClass} mb-3`} key={node._uid}>
                    <div className={`card h-100 text-center ${cardClass}`}>
                      <div className="card-header border-0 p-0">
                        <img
                          src={ImageHandler(node.image.filename, '500x500')}
                          className="w-100"
                          alt={node.title}
                        />
                      </div>
                      <div className="card-body pt-lg-5 pb-lg-5">
                        <h3 className="card-title brand-font mb-4">
                          {node.title}
                        </h3>
                        <div className="card-text">
                          <Richtext content={node.body} />
                        </div>

                        <Stores
                          ios={node.link_appstore.url}
                          play={node.link_playstore.url}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

const Stores = (props) => {
  const { ios, play } = props;

  return (
    <ul className="list-unstyled pt-3">
      {ios && (
        <li className="list-inline-item">
          <a href={ios} target="_blank" rel="noopener noreferrer">
            <img src={AppStore} alt="Download on the App Store" width="140px" />
          </a>
        </li>
      )}
      {play && (
        <li className="list-inline-item">
          <a href={play} target="_blank" rel="noopener noreferrer">
            <img
              src={PlayStore}
              alt="Download on the Play Store"
              width="140px"
            />
          </a>
        </li>
      )}
    </ul>
  );
};

export default Games;

// ====

Games.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  layout: PropTypes.string,
  copy: PropTypes.shape,
  items: PropTypes.arrayOf,
  image: PropTypes.shape({
    filename: PropTypes.string,
    alt: PropTypes.string,
  }),
};

Games.defaultProps = {
  id: null,
  title: null,
  layout: 'natural',
  copy: null,
  items: false,
  image: {
    filename: null,
    alt: null,
  },
};
